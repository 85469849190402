import React from 'react';
import PropTypes from 'prop-types';
import './advancedButtonStyles.scss';
import { ELEMENT_TYPES, getKadenceBlock } from '../utils';

export const AdvancedButton = (props) => {
    const button = getKadenceBlock(props, ELEMENT_TYPES.BUTTON);
    return (<>
        {button.style}
        {button.component}
    </>
    );
};

AdvancedButton.propTypes = {
    attrs: PropTypes.object
}
